body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  font-family: 'Lato', sans-serif;
  font-family: 'Fira Sans', sans-serif;
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: overlay;
  overflow-x: hidden;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ReactModal__Content--after-open .react-datepicker-wrapper {
  width: 100%;
}

::placeholder {
  color: #979eaa;
  opacity: 1;
  border: none;
  padding: 0%;
  width: 100%;
  font-family: Lato;
  font-style: italic;
  outline: none;
  align-self: flex-end;
}

::-webkit-scrollbar-thumb {
  background: #cecece;
  width: 6.64px;
  height: 120px;
  border-radius: 9.2px;

  padding: 0 6px;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b6b5b5;
}

::-webkit-scrollbar-track {
  background: none;
}

.Collapsible {
  width: 100%;
}

.Collapsible__trigger {
  display: block;
  position: relative;
  margin-bottom: 5px;
  padding: 5px;
  font-weight: 600;
  background: rgba(222, 221, 221, 0.5);
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.Collapsible__trigger.is-open .arrow {
  transform: rotate(90deg);
  transition: transform 250ms ease-in-out 25ms;
}

.Collapsible__trigger.is-closed .arrow {
  transform: rotate(0deg);
  transition: transform 250ms ease-in-out 25ms;
}

.Collapsible__contentOuter {
  overflow: hidden !important;
  scrollbar-width: none;
  margin-bottom: 10px;
}
